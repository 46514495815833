// 1. Configuration and helpers
@import 'abstracts/functions';
@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/utilities';
@import 'abstracts/animations';
// 2. Base stuff
@import 'base/typography';
@import 'base/reset';
// 6. Themes
// 7. Vendors
@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '~react-datepicker/dist/react-datepicker.css';
// 3. Layout-related sections
@import 'layout/wrapper';
// 4. Components
@import 'components/form';
@import 'components/video-player';
@import 'components/slides';
@import 'components/breadcrumb';
@import 'components/navbar';
@import 'components/animated-switch';
@import 'components/chip-input';
@import 'components/icon';
@import 'components/image-container';
@import 'components/documents';
@import 'components/indicators';
// @import 'components/checkbox';
// 5. Page-specific styles
@import 'pages/site-admin/login';
@import 'pages/site-admin/documents';
@import 'pages/site-admin/notifications';
@import 'pages/site-admin/treatment-summaries';
@import 'pages/super-admin/kits/kit-details';
@import 'pages/super-admin/user-account/user-account';
@import 'pages/super-admin/manage-banner';
@import 'pages/super-admin/documents';
// 8. Vendors' Extensions
@import 'vendors-extension/sizing';
@import 'vendors-extension/bootstrap-table';
@import 'vendors-extension/card';
@import 'vendors-extension/bootstrap-rgba-colors';
@import 'vendors-extension/modals';
@import 'vendors-extension/others';
@import 'vendors-extension/react-datepicker';
@import 'vendors-extension/chartjs';
