.slides {
  .carousel {
    .carousel-control-prev,
    .carousel-control-next {
      transition: all 0.25s ease;
      background: transparent;
    }
    .carousel-control-prev {
      .carousel-control-prev-icon {
        transform: scale(1.5);
      }
      &:hover {
        background: linear-gradient(to right, #252525, transparent);
      }
    }
    .carousel-control-next {
      .carousel-control-next-icon {
        transform: scale(1.5);
      }
      &:hover {
        background: linear-gradient(to left, #252525, transparent);
      }
    }
    .carousel-item {
      height: 20em;
      @include media-breakpoint-up(md) {
        height: 70vh;
      }
      > *:first-child {
        background: {
          // image: set inline on the element
          position: 50%;
          repeat: no-repeat;
          size: cover;
        }
        height: inherit;
      }
    }
  }
}

.chart-slider-container {
  .carousel.chart-slider {
    .carousel-control-prev,
    .carousel-control-next {
      background: none;
      width: auto;
    }
  }
  .chart-legend {
    height: 1rem;
    width: 1rem;
    top: 2px;
    left: -4px;
  }
}
