.text-blue {
  &.btn {
    &:hover,
    &:active,
    &:focus {
      color: darken($color: $blue, $amount: 20%);
    }
  }
}
.border-black {
  border-color: #000000 !important;
}
