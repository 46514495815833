.manage-banner-container {
  .banner-card {
    .banner-indicator-container {
      position: absolute;
      top: -1px;
      z-index: 1;
      opacity: 0;
      transition: top 0.25s ease-in-out, opacity 0.25s ease-in-out;

      .banner-index {
        height: 26px;
        width: 26px;
      }
    }

    &:hover {
      .banner-indicator-container {
        top: 8px;
        opacity: 1;
      }
    }
  }
}
