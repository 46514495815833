.breadcrumb {
  background-color: #fff;
  margin-bottom: 1rem !important;

  .breadcrumb-item {
    // font-size: 0.8rem;
    &.active {
      span.active {
        @extend .text-primary;
      }
    }

    &:not(.active) {
      a {
        @extend .text-muted;
      }
    }

    & + .breadcrumb-item::before {
      content: '>';
    }
  }
}
