html,
body,
#root // .container-fluid

 {
  height: 100%;
  font-size: $font-size-default;
  letter-spacing: $font-letter-spacing;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  vxl: 1500px
);
