.chip-input {
  @extend .border;
  transition: border 0.15s linear, box-shadow 0.15s linear;
  &.chip-input-focus {
    background-color: #fff;
    border-color: #bbd5ff;
    border-right-color: rgb(187, 213, 255);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 134, 255, 0.25);
  }
  .chip {
    @extend .cursor-pointer;
    transform: translateX(10px);
    opacity: 0;
    padding: 3px 20px 3px 20px;
    border: 1px solid $chip-border-color;
    border-radius: 16px;
    height: 32px;
    transition: box-shadow 0.15s linear, background-color 0.15s linear;
    &:hover,
    &:focus,
    &:active {
      @extend .shadow-sm, .text-dark;
      background-color: $tr-hover-background-color;
    }
    &.active {
      @extend .shadow-sm, .text-dark;
      background-color: $tr-hover-background-color;
    }
    &.show {
      animation: $move-left-in 0.25s ease-in-out forwards;
    }
    &.hide {
      animation: $move-right-out 0.25s ease-in-out forwards;
    }
    svg {
      transition: height 0.15s linear, width 0.15s linear;
      &:hover,
      &:focus,
      &:active {
        transform: scale(1.25);
      }
    }
  }
}
