@keyframes move-left {
  from {
    transform: translateX(10px);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes move-left-in {
  from {
    transform: translateX(10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes move-left-out {
  from {
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    transform: translateX(10px);
    opacity: 0;
  }
}

@keyframes move-right {
  from {
    transform: translateX(-10px);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes move-right-in {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes move-right-out {
  from {
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

$move-left: move-left;
$move-left-in: move-left-in;
$move-left-out: move-left-out;
$move-right: move-right;
$move-right-out: move-right-out;
$move-right-in: move-right-in;

.move-left {
  transform: translateX(10px);

  &.animate {
    animation: $move-left 0.25s ease-in-out forwards;
  }
}

.move-left-in {
  transform: translateX(10px);
  opacity: 0;

  &.animate {
    animation: $move-left-in 0.25s ease-in-out forwards;
  }
}

.move-left-out {
  transform: translateX(0px);
  opacity: 1;

  &.animate {
    animation: $move-left-out 0.25s ease-in-out forwards;
  }
}

.move-right {
  transform: translateX(-10px);

  &.animate {
    animation: $move-right 0.25s ease-in-out forwards;
  }
}

.move-right-in {
  transform: translateX(-10px);
  opacity: 0;

  &.animate {
    animation: $move-right-in 0.25s ease-in-out forwards;
  }
}

.move-right-out {
  transform: translateX(0px);
  opacity: 1;

  &.animate {
    animation: $move-right-out 0.25s ease-in-out forwards;
  }
}

@keyframes scaleUp {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.scaleUp {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: scaleUp;
  animation-name: scaleUp;
}

@keyframes floating {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(8%);
  }

  100% {
    transform: translateY(0%);
  }
}

.floating {
  animation: floating 1.5s infinite;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 500ms;
  animation-fill-mode: both;
}
