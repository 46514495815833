.mh-500px {
  min-height: 500px;
}

// this will create size classes with breakpoints.
// e.g. h-sm-50, w-md-75, etc.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  60px: 60px
);
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}
