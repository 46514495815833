// colors
$primary: #b11e29;
$success: #53ac2c;
$blue: #3b86ff;
$light: #fcfcfc;
$white: #fff;
$dark: #343a40;
// $text-muted: #C6C6C6;
$theme-colors: (
  'primary': $primary,
  'success': $success,
  'info': $blue,
  'light': $light
);

// fonts

$font-size-default: 16px;
$font-color-default: #4d4f5c;
$font-color-1: #d1d1d1;
$font-letter-spacing: 0.25px;

// form

$form-input-shadow-color: rgba(177, 30, 41, 0.25);

// table

$th-background-color: #f2f2f2;
$td-background-color: #fcfcfc;
$tr-hover-background-color: rgba(0, 0, 0, 0.1);

// chip-input

$chip-border-color: #2d84e8;

// override bootstrap variables

$body-color: $font-color-default;
$enable-gradients: true;
$input-placeholder-color: #a8a8a8;

// inputs

$input-height: 3.0625rem;
$input-focus-box-shadow: 0 0 0 0.2rem rgba(59, 134, 255, 0.25);
$input-focus-border-color: #bbd5ff;

// Checkbox

// if you want to enable below,
// un-comment it and also make sure to import src/styles/components/_checkbox.scss in src/styles/main.scss

// $custom-control-indicator-checked-color: $primary;
// $custom-control-indicator-checked-bg: $white;
// $custom-control-indicator-checked-border-color: $dark;
// $custom-control-indicator-size: 1.125rem;
// $custom-checkbox-indicator-border-radius: 0.125rem;
// $custom-checkbox-indicator-icon-checked: str-replace(
//   url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'/%3e%3c/svg%3e"),
//   '#',
//   '%23'
// );
// $custom-forms-transition: background-color 0.15s ease-in-out,
//   border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
//   background-size 5s ease-in-out;
