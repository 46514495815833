.pdf-preview {
  @extend .border, .rounded, .w-100, .h-100;

  .react-pdf__Document {
    .react-pdf__Page {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;

      .react-pdf__Page__canvas,
      .react-pdf__Page__svg {
        @extend .w-100;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      .react-pdf__Page__svg > svg {
        @extend .w-100, .h-auto;
      }

      .react-pdf__Page__textContent {
      }
    }
  }
}
