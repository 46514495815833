.react-datepicker-wrapper {
  @extend .w-100;

  .react-datepicker__input-container {
    @extend .w-100;

    .react-datepicker__close-icon {
      &::after {
        background-color: transparent;
        color: $font-color-default;
        font-size: 26px;
        padding: 0;
      }
    }

    input {
      @extend .form-control, .rounded-0;

      &:not(.form-control-sm) {
        height: $input-height;
        // line-height: $input-height;
        line-height: normal;

        & + .react-datepicker__close-icon {
          &::after {
            height: 18px;
          }
        }
      }

      &.form-control-sm {
        height: calc(1.8125rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        // line-height: 1.5;
        line-height: normal;
        border-radius: 0.2rem;

        & + .react-datepicker__close-icon {
          &::after {
            height: 12px;
            line-height: 0;
          }
        }
      }

      @extend %form-control-focus;
    }
  }
}

.react-datepicker-popper {
  .react-datepicker {
    @extend .shadow-sm;

    .react-datepicker__month-container {
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day--today {
            @extend .bg-primary, .rounded, .text-white, .font-weight-bold;

            &:hover,
            &:focus,
            &:active {
              @extend .text-white;
            }
          }

          .react-datepicker__day {
            &:not(.react-datepicker__day--today) {
              &:hover,
              &:focus,
              &:active {
                @extend .text-primary;
              }
            }
          }
        }
      }
    }
  }
}

.datepicker-icon {
  top: 17px;
  right: 16px;
  pointer-events: none;

  &.sm {
    top: 8px;
    right: 8px;
  }
}

.react-datepicker-wrapper .react-datepicker__input-container input.is-invalid {
  background: none;
  padding: 0.375rem 0.75rem;
}
