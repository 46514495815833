#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  pointer-events: none;
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.ReactChart {
  height: 90px !important;
}

.tooltip-wrap {
  z-index: 5;
  left: -4px !important;
}