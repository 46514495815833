.main-nav {
  margin-right: -15px;
  margin-left: -15px;

  .user-image {
    @extend .border, .border-dark, .rounded-circle;
    top: 50%;
    left: 0;
    margin-top: -18px;
    padding: 2px;
  }

  a.nav-link {
    z-index: auto;
  }
  a.nav-link.active {
    @extend .text-primary, .font-weight-bold;

    &:hover,
    &:focus {
      @extend .text-primary;
    }
  }

  .user-account-name {
    max-width: 200px;
  }
}
