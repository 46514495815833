.input-height {
  min-height: $input-height;
}

%input-group-prepend {
  // line-height: $input-height;
  line-height: normal;

  .input-group-text {
    width: $input-height;
  }
}

%form-control {
  .form-control {
    &:not(textarea) {
      line-height: normal;
    }
  }
}

%form-control-focus {
  &:focus,
  &:active {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }
}

%input-group {
  .input-group {
    .input-group-prepend,
    .input-group-append {
      @extend %input-group-prepend;
    }

    @extend %form-control;
  }
}

.invalid-feedback-font-size {
  font-size: 80%;
}

.custom-form-group {
  &.form-group {
    @extend %input-group;
  }
}

.custom-form-control {
  @extend %form-control;
}

// dropdowns
.dropdown-toggle {
  @extend %form-control-focus;

  &:not(.no-arrow-icon) {
    padding-right: 34px;
    position: relative;

    &::after {
      border: 0;
      background: url('../assets/icons/down-arrow.png') no-repeat center;
      display: inline-block;
      width: 18px;
      height: 18px;
      vertical-align: middle;
      background-size: 16px;
      position: absolute;
      top: 50%;
      margin-top: -9px;
      right: 12px;
    }
  }

  &.no-arrow-icon {
    &::after {
      display: none;
    }
  }
}

.dropdown-menu {
  &.scrolled-height {
    max-height: 300px;
    overflow-y: auto;
  }
}

// radios
.form-check {
  .form-check-input[type='radio'] {
    height: 1rem;
    width: 1rem;
    opacity: 0;

    & + .form-check-label {
      @extend .pl-3, .cursor-pointer;

      &:after {
        @include transform(scale(0));
        @include transition(all 0.25s ease-in-out);
        border-radius: 50%;
        background-color: $font-color-default;
        content: '';
        position: absolute;
        top: 7px;
        left: 4px;
      }

      &:before {
        @extend .mr-1, .align-middle;
        border-radius: 50%;
        border: 2px solid $font-color-default;
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
      }
    }

    &:checked + .form-check-label {
      &:after {
        @include transform(scale(1));
      }
    }
  }

  &.form-check-success {
    :checked + .form-check-label {
      &:after {
        background-color: $success;
      }

      &:before {
        border-color: $success;
      }
    }
  }

  &:not(.font-size-lg) {
    .form-check-label {
      &:after {
        height: 12px;
        width: 12px;
      }

      &:before {
        height: 20px;
        width: 20px;
      }
    }
  }

  &.font-size-lg {
    .form-check-label {
      &:after {
        height: 16px;
        width: 16px;
      }

      &:before {
        height: 24px;
        width: 24px;
      }
    }
  }
}

// list-group
form {
  .list-group.list-group-flush {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

// form-group-list
form.form-group-list {
  .custom-form-group {
    @extend .mb-0;
  }
}

.hide-feedback {
  .invalid-feedback {
    display: none !important;
  }
}

// select
.custom-select-white {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: transparent;
  color: white;
}
.padding-button{
  padding-left: 13px;
  padding-right: 12px;
}

.ToggleButton-color > div{
  display: inline-flex !important;
  margin-right: 12px;
}
