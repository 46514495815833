// actions
.hoverable {
  transition: box-shadow 0.15s linear, background-color 0.15s linear;

  &:hover {
    @extend .shadow;
  }

  &.inverse {
    &:hover {
      @extend .shadow-none;
    }
  }

  &.grey {
    &:hover {
      @extend .shadow-none;
      background-color: $th-background-color !important;
    }
  }
}

// border
.border.border-thick {
  border-width: 2px !important;
}

.border.border-dashed {
  border-style: dashed !important;
}

// cursor
.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

// display
.opacity-35 {
  opacity: 0.35;
}

//font
.font-size-sm {
  font-size: 87.5%;
}

.font-weight-md {
  font-weight: 500;
}

.font-size-lg {
  font-size: 125%;
}

.font-size-xl {
  font-size: 150%;
}

//form
.no-focus {
  &:focus {
    box-shadow: none !important;
  }
}

.shadow-primary {
  border: 1px solid #e6697e;
  box-shadow: 0 0 0 0.2rem $form-input-shadow-color;
}

// object
.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

// pointer events
.pointer-events-none {
  pointer-events: none;
}

// position
.position-absolute {
  &.top-left-0 {
    top: 0;
    left: 0;
  }

  &.top-left-50 {
    top: 50%;
    left: 50%;
  }
}

// rotate
.rotate-180 {
  transform: rotate(180deg);
}

// spacing
.mx--15px {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.mx--30px {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

// width

.mw-97 {
  max-width: 97% !important;
}
