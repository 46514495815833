.documents-row {
  > div {
    > .card {
      .document-thumb-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 1.25rem;
        height: 200px;
      }
    }
  }
}

.document-image-preview {
  min-height: 200px;
  max-height: 500px;
}

.document-pdf-preview {
  padding: 56.25% 0 0 0;
  position: relative;

  .document-pdf-preview-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
