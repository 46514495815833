// ref: src\app\_shared\components\ImageContainer.jsx

.image-container {
  min-width: 14px;
  min-height: 14px;

  .image-loader {
    top: 50%;
    left: 50%;
    margin-top: -14px;
    margin-left: -14px;
  }

  // &.errored {
  //   @extend .d-flex, .justify-content-center, .align-items-center;

  //   img {
  //     height: 36px !important;
  //     width: 36px !important;
  //   }
  // }
}
