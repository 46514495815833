$login-bg-layer-color: rgba(177, 30, 41, 0.7);
$login-company-font-color: $font-color-1;
$login-page-company-logo-height: 246.5px;

.login-page {
  margin-bottom: -400px;

  @include media-breakpoint-up(sm) {
    margin-bottom: -350px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: -300px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: -200px;
  }

  .company-logo-container {
    height: 150px;
  }

  .login-background {
    background-image: url('../assets/images/login-background.png');
    background-size: cover;
    height: 100%;
    position: relative;

    .layer {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $login-bg-layer-color;

      .company-logo {
        height: $login-page-company-logo-height;
      }
    }
  }

  .login-form-row {
    height: 93%;
  }

  .login-form-row-admin {
    height: 90%;
  }
}
