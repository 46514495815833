.btn {
  .svg-icon.btn-icon {
    height: 26px;
    width: 26px;
    margin-top: -3px;
  }

  &:hover {
    .svg-icon.icon-outline-primary {
      fill: #ffffff;
    }
  }
}
