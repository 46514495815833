.bg-dark-rgba-5 {
  background-color: transparent;
  background-color: rgba($dark, 0.5);
}

.bg-dark-rgba-7 {
  background-color: transparent;
  background-color: rgba($dark, 0.7);
}

.bg-dark-rgba-3 {
  background-color: transparent;
  background-color: rgba($dark, 0.3);
}

.bg-dark-rgba-1 {
  background-color: transparent;
  background-color: rgba($dark, 0.1);
}

.bg-primary-rgba-1 {
  background-color: transparent;
  background-color: rgba($primary, 0.1);
}

.bg-black-rgba-7 {
  background-color: transparent;
  background-color: rgba(#000000, 0.7);
}

.text-blue {
  color: $blue;
}

// below will generate bg colors as per chart colors
$chart-green: #16cec0;
$chart-blue: #2d8dfd;
$chart-orange: #ff9f4a;
$chart-yellow: #ffd300;
$chart-tomato-pink: #f881af;
$chart-colors: (
  'chart-green': $chart-green,
  'chart-blue': $chart-blue,
  'chart-orange': $chart-orange,
  'chart-yellow': $chart-yellow,
  'chart-tomato-pink': $chart-tomato-pink
);

@each $color-name, $color-value in $chart-colors {
  .bg-#{$color-name} {
    background-color: $color-value;
  }
}

// this will generate rgba colors from bootstrap colors and theme-colors
// examples : bg-light-rgba-1, text-dark-rgba-8, bg-primary-rgba-3, text-purple-rgba-5
// if we are going to use, many colors, we will un-comment below lines
// $all-colors: map-merge($map1: $colors, $map2: $theme-colors);
// $transparencies: (1:0.1, 2: 0.2, 3: 0.3, 4: 0.4, 5: 0.5, 6: 0.6, 7: 0.7, 8: 0.8, 9: 0.9);
// @each $color1 in map-keys($all-colors) {
//     @each $alpha,
//     $opacity in $transparencies {
//         @each $prop1,
//         $abbrev1 in (background-color: bg, color: text) {
//             .#{$abbrev1}-#{$color1}-rgba-#{$alpha} {
//                 $main-color: map-get($map: $all-colors, $key: $color1); // fallback if rgba not supported
//                 #{$prop1}: $main-color;
//                 #{$prop1}: rgba($main-color, $opacity);
//             }
//         }
//     }
// }
// @each $color2 in map-keys($colors) {
//     @each $prop2,
//     $abbrev2 in (background-color: bg, color: text) {
//         .#{$abbrev2}-#{$color2} {
//             #{$prop2}: map-get($map: $colors, $key: $color2);
//         }
//     }
// }
