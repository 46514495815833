// ref : src\app\super-admin\pages\KitDetail\Details.jsx
.img-kit-detail {
  max-height: 128px;
}

// ref: src\app\super-admin\pages\KitDetail\table.constants.js
.quantity-dropdown {
  @extend .shadow-sm, .rounded-0;
  max-height: 173px;
  overflow-y: auto;
}
