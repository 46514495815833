.notification-row {
  background-color: $td-background-color;
  .mark-as-read-btn-container {
    width: 0;
    transform: scale(0);
    transition: width 0.15s linear, transform 0.15s linear;
    .svg-icon {
      margin-top: -9px;
    }
  }
  &:hover {
    .mark-as-read-btn-container {
      width: 27px;
      transform: scale(1);
      opacity: 1;
    }
  }
}
