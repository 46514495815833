.react-bootstrap-table {
  .table {
    tbody {
      tr {
        td {
          @extend .align-middle;

          &:not(.no-text-truncate) {
            @extend .text-truncate;
          }
        }

        &:hover,
        &:focus {
          td {
            background-color: $tr-hover-background-color !important;
          }
        }

        &.colored-background {
          td {
            background-color: $td-background-color;
          }
        }
      }
    }

    &.no-data-p-0 {
      tbody {
        tr {
          td {
            &.react-bs-table-no-data {
              @extend .p-0;
            }
          }
        }
      }
    }

    &.with-gap {
      border-collapse: separate;
      border-spacing: 0 10px;

      td {
        border-top: none;
      }
    }

    &.table-layout-auto {
      table-layout: auto;
    }

    thead {
      tr {
        th {
          @extend .font-weight-normal, .border-bottom-0, .border-top-0;

          background-color: $th-background-color;
        }
      }
    }
  }
}

.row.react-bootstrap-table-pagination {
  background-color: $th-background-color !important;

  @extend .bg-light, .align-items-center;

  > div {
    @extend .my-2;

    .react-bs-table-sizePerPage-dropdown.dropdown {
      padding-right: 1.3rem;

      .dropdown-toggle {
        font-size: 14px;
        line-height: 18px;

        &::after {
          background-size: 12px;
          vertical-align: -4px;
        }
      }
    }

    .pagination.react-bootstrap-table-page-btns-ul {
      li.page-item {
        a.page-link {
          font-size: 14px;
          line-height: 18px;
        }
        &:not(.active) {
          a.page-link {
            background-color: $th-background-color !important;
          }
        }

        &:first-child,
        &:last-child {
          background-size: 14px;
          background-position: center;
          background-repeat: no-repeat;

          a.page-link {
            @extend .h-100;

            background-color: transparent !important;
            color: transparent;
          }
        }

        &:last-child {
          &:not(.disabled) {
            background-image: url('../assets/icons/double-angle-pointing-to-right.png');
          }

          &.disabled {
            background-image: url('../assets//icons/double-angle-pointing-to-right_disable.png');
          }
        }

        &:first-child {
          &:not(.disabled) {
            background-image: url('../assets/icons/double-angle-pointing-to-left.png');
          }

          &.disabled {
            background-image: url('../assets//icons/double-angle-pointing-to-left_disable.png');
          }
        }
      }
    }

    &:first-child {
      @extend .order-2, .text-right;

      .dropdown {
        .dropdown-toggle.btn.btn-secondary {
          background-image: none;

          @extend .border, .rounded-0, .text-dark, .bg-white;
        }
      }
    }

    &:last-child {
      .pagination.react-bootstrap-table-page-btns-ul {
        @extend .mb-0;

        li.page-item {
          &:not(:last-child):not(:first-child) {
            padding-top: 3px;

            a.page-link {
              @extend .rounded-circle, .mx-1;

              padding: 5px 10px;
            }
          }

          &:not(.active) {
            a.page-link {
              @extend .border-0;

              &:hover,
              &:focus {
                background-color: initial;
              }
            }
          }

          &.active {
            a.page-link {
              &:hover,
              &:focus {
                background-color: $primary;
              }
            }
          }
        }
      }
    }
  }
}
