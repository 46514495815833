.wrapper {
  min-height: 100%;
  position: relative;
  padding-bottom: 260px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 260px;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 360px;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 360px;
  }

  &.wrapper-super-admin {
    padding-bottom: 60px;
  }

  &.wrapper-path-,
  &.wrapper-path-login,
  &.wrapper-path-admin,
  &.wrapper-path-forgot-password {
    padding-bottom: 200px;
  }
}
